<template>
    <div class="p-3">
        <div class="text-center">
            <el-radio-group v-model="status" @change="loadProducts(true)">
                <el-radio-button :label="null">全部</el-radio-button>
                <el-radio-button :label="1">填写中</el-radio-button>
                <el-radio-button :label="2">待审核</el-radio-button>
                <el-radio-button :label="3">已审核</el-radio-button>
                <el-radio-button :label="100">已退回</el-radio-button>
            </el-radio-group>
        </div>

        <el-card shadow="never" class="mt-3">
            <div class="row no-gutters">
                <div class="col">
                    <el-select v-model="productTypeId" placeholder="商品类型" style="width: 240px;"
                        @change="loadProducts(true)">
                        <el-option :value="null" label="全部商品类型" />
                        <el-option v-for="productType in productTypes" :key="productType.id" :value="productType.id"
                            :label="productType.name">
                            <span :style="{marginLeft: (productType.id.length-4)*4+'px'}">{{productType.name}}</span>
                        </el-option>
                    </el-select>
                    <search-box v-model="key" class="ml-2" style="width: 240px;" @search="loadProducts(true)" />
                </div>
                <div class="col-auto">
                    <el-button type="primary" @click="showProductDialog(null)">添加</el-button>
                </div>
            </div>
            <el-table :data="products.data" class="mt-2">
                <el-table-column width="90">
                    <template #default="scope">
                        <el-image :src="scope.row.pictureUrl" :preview-src-list="[scope.row.pictureUrl]" fit="fill"
                            style="width: 64px; height: 64px;" />
                    </template>
                </el-table-column>
                <el-table-column label="名称">
                    <template #default="scope">
                        <div :title="scope.row.name" class="text-truncate">
                            <span class="text-warning">[{{scope.row.statusName}}]</span>
                            <router-link :to="'/product/detail?id='+scope.row.id" target="_blank">
                                {{scope.row.name}}
                            </router-link>
                        </div>
                        <div class="text-info">
                            [{{scope.row.productTypeName}}]
                            {{scope.row.specification}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="number" label="编号" width="150" />
                <el-table-column prop="unit" label="计量单位" width="120" />
                <el-table-column prop="price" label="价格（元）" width="120" />
                <el-table-column label="操作" fixed="right" width="140">
                    <template #default="scope">
                        <el-button v-if="scope.row.status==3&&!scope.row.isOnSale" type="text"
                            @click="updateProductIsOnSale(scope.row.id,true)">
                            上架
                        </el-button>
                        <el-button v-if="scope.row.status==3&&scope.row.isOnSale" type="text" class="text-warning"
                            @click="updateProductIsOnSale(scope.row.id,false)">
                            下架
                        </el-button>
                        <el-button v-if="scope.row.status==1||scope.row.status==100" type="text"
                            @click="submitProduct(scope.row.id)">
                            提交
                        </el-button>
                        <el-button type="text" @click="showProductDialog(scope.row)">
                            修改
                        </el-button>
                        <el-button type="text" class="text-danger" @click="deleteProduct(scope.row.id)">
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination layout="total,->,prev,pager,next" :total="products.totalRecords"
                :current-page="productsPagination.currentPage" :page-size="productsPagination.pageSize" class="mt-3"
                @current-change="productsPaginationCurrentChange" />
        </el-card>

        <el-dialog :visible.sync="productDialogVisible" title="商品" :close-on-click-modal="false" width="960px" top="0">
            <div style="margin: 0 -16px;">
                <el-form>
                    <div class="form-group-title">基本信息</div>
                    <div class="form-group">
                        <el-row :gutter="8">
                            <el-col :span="12">
                                <el-form-item label="商品类型">
                                    <el-select v-model="product.productTypeId" placeholder="商品类型">
                                        <el-option v-for="productType in productTypes" :key="productType.id"
                                            :value="productType.id" :label="productType.name">
                                            <span
                                                :style="{marginLeft: (productType.id.length-4)*4+'px'}">{{productType.name}}</span>
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="名称">
                                    <el-input v-model="product.name" placeholder="名称" />
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row :gutter="8">
                            <el-col :span="12">
                                <el-form-item label="条形码">
                                    <el-input v-model="product.barcode" placeholder="条形码" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="编号">
                                    <el-input v-model="product.number" placeholder="编号" />
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row :gutter="8">
                            <el-col :span="12">
                                <el-form-item label="计量单位">
                                    <el-input v-model="product.unit" placeholder="计量单位" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="规格">
                                    <el-input v-model="product.specification" placeholder="规格" />
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>

                    <div class="form-group-title">价格</div>
                    <div class="form-group">
                        <el-row :gutter="8">
                            <el-col :span="8">
                                <el-form-item label="价格（元）">
                                    <el-input-number v-model="product.price" controls-position="right" :min="0" />
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>

                    <div class="form-group-title">库存</div>
                    <div class="form-group">
                        <el-row :gutter="8">
                            <el-col :span="8">
                                <el-form-item label="期初库存量">
                                    <el-input-number v-model="product.beginningQuantity" controls-position="right"
                                        :min="0" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="最低库存量">
                                    <el-input-number v-model="product.minQuantity" controls-position="right" :min="0" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="最高库存量">
                                    <el-input-number v-model="product.maxQuantity" controls-position="right" :min="0" />
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>

                    <div class="form-group-title">图文详情</div>
                    <div class="form-group">
                        <div>
                            <div>图片</div>
                            <div class="border rounded mt-2 p-1">
                                <picture-uploader v-model="product.pictures" />
                            </div>
                        </div>

                        <el-form-item label="详情" class="mt-2">
                            <html-editor v-model="product.detail" height="240px" placeholder="详情" />
                        </el-form-item>
                    </div>
                </el-form>
            </div>
            <template #footer>
                <el-button type="primary" @click="saveProduct">确定</el-button>
                <el-button @click="productDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                status: null,
                productTypes: [],
                productTypeId: null,
                key: null,
                products: {},
                productsPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
                product: {},
                productDialogVisible: false,
            };
        },
        methods: {
            async loadProductTypes() {
                let response = await this.$axios.get('/api/ProductType/GetProductTypes');
                this.productTypes = response.data;
            },
            async loadProducts(resetPage = false) {
                if (resetPage) {
                    this.productsPagination.currentPage = 1;
                }

                let response = await this.$axios.get('/api/Product/GetProducts', {
                    params: {
                        supplierId: this.$store.state.user.id,
                        status: this.status,
                        productTypeId: this.productTypeId,
                        key: this.key,
                        pageIndex: this.productsPagination.currentPage - 1,
                        pageSize: this.productsPagination.pageSize,
                    }
                });
                this.products = response.data;
            },
            productsPaginationCurrentChange(page) {
                this.productsPagination.currentPage = page;
                this.loadProducts();
            },
            showProductDialog(product) {
                if (product) {
                    this.product = JSON.parse(JSON.stringify(product));
                }
                else {
                    this.product = {
                        price: 0,
                        beginningQuantity: 0,
                        inQuantity: 0,
                        outQuantity: 0,
                        minQuantity: 0,
                        maxQuantity: 0,
                        pictures: [],
                    };
                }
                this.productDialogVisible = true;
            },
            async saveProduct() {
                if (this.product.id == undefined) {
                    await this.$axios.post('/api/Product/AddProduct', {
                        productTypeId: this.product.productTypeId,
                        name: this.product.name,
                        barcode: this.product.barcode,
                        number: this.product.number,
                        unit: this.product.unit,
                        specification: this.product.specification,
                        remark: this.product.remark,
                        price: this.product.price,
                        beginningQuantity: this.product.beginningQuantity,
                        minQuantity: this.product.minQuantity,
                        maxQuantity: this.product.maxQuantity,
                        pictures: this.product.pictures,
                        detail: this.product.detail,
                    });
                    this.productDialogVisible = false;
                    this.loadProducts();
                }
                else {
                    await this.$axios.post('/api/Product/UpdateProduct', {
                        id: this.product.id,
                        productTypeId: this.product.productTypeId,
                        name: this.product.name,
                        barcode: this.product.barcode,
                        number: this.product.number,
                        unit: this.product.unit,
                        specification: this.product.specification,
                        remark: this.product.remark,
                        price: this.product.price,
                        beginningQuantity: this.product.beginningQuantity,
                        minQuantity: this.product.minQuantity,
                        maxQuantity: this.product.maxQuantity,
                        pictures: this.product.pictures,
                        detail: this.product.detail,
                    });
                    this.productDialogVisible = false;
                    this.loadProducts();
                }
            },
            async updateProductIsOnSale(id, isOnSale) {
                if (!confirm(`确定要${isOnSale ? '上架' : '下架'}吗？`)) {
                    return;
                }

                await this.$axios.post('/api/Product/UpdateProductIsOnSale', {
                    id: id,
                    isOnSale: isOnSale,
                });
                this.loadProducts();
            },
            async submitProduct(id) {
                if (!confirm('确定要提交吗？')) {
                    return;
                }

                await this.$axios.post('/api/Product/SubmitProduct', { id: id });
                this.loadProducts();
            },
            async deleteProduct(id) {
                if (!confirm('确定要删除吗？')) {
                    return;
                }

                await this.$axios.post('/api/Product/DeleteProduct', { id: id });
                this.loadProducts();
            },
        },
        created() {
            this.loadProductTypes();
            this.loadProducts();
        },
    };
</script>

<style>
    .form-group-title {
        background-color: #f2f6fc;
        padding: 1em 3em;
        border-left: 4px solid #409eff;
        font-weight: bold;
    }

    .form-group {
        padding: 1em 3em;
    }
</style>